import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  baseURL: '/api/v1/',
  logLevel: "information",
  logType: "backend",
  salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
  serverURL: 'https://app.uat.ownid.com',
  maxProfileFieldsCount: 50,
  envName: 'uat',
  translationsUrlPrefix: 'https://i18n.dev.ownid.com/',
  translationsUrlSuffix: '/web-app.json',
};
